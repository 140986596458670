import {
      LiftPlaque_01,
      LiftPlaque_02,
      LiftPlaque_03,
      LiftPlaque_Logo,
      LiftPlaque_04,
      LiftPlaque_Сatalogue_01,
      LiftPlaque_Сatalogue_02,
      LiftPlaque_Сatalogue_03,
      LiftPlaque_Сatalogue_04,
      LiftPlaque_Сatalogue_05,
      LiftPlaque_Сatalogue_06,
      WAConception_01,
      WAConception_02,
      WAConception_03,
      WAConception_04,
      WAConception_Logo,
      WAConception_Сatalogue_01,
      WAConception_Сatalogue_02,
      GCEI_01,
      GCEI_02,
      GCEI_03,
      GCEI_04,
      GCEI_Logo,
      GCEI_Сatalogue_01,
      GCEI_Сatalogue_02,
} from '../assets/index'

export const companies = [
      {
            name: "GCEI",
            cover: GCEI_02,
            images: [GCEI_04, GCEI_01, GCEI_03],
            url: "https://gceifrance.fr/",
            color: 'rgb(0, 123, 183 )',
            logo: GCEI_Logo,
            videos: [],
            files: [GCEI_Сatalogue_01, GCEI_Сatalogue_02]
      },
      {
            name: "WA CONCEPTION",
            cover: WAConception_01,
            images: [WAConception_02, WAConception_03, WAConception_04],
            url: "https://www.wa-conception.com/",
            color: 'rgb(149, 206, 0 )',
            logo: WAConception_Logo,
            files: [WAConception_Сatalogue_01, WAConception_Сatalogue_02],
            videos: []
      },
      {
            name: "LIFTPLAQUE",
            cover: LiftPlaque_01,
            images: [LiftPlaque_02, LiftPlaque_04, LiftPlaque_03],
            url: "https://liftplaq.fr/",
            color: 'rgb(255, 144, 0 )',
            logo: LiftPlaque_Logo,
            videos: ["https://www.youtube.com/embed/dO2r_DUsArk?si=k4lDxJjBQdqG8oMo&amp;controls=0", "https://www.youtube.com/embed/dO2r_DUsArk?rel=0&amp;mute=1", "https://www.youtube.com/embed/ad6i6gptQ_g?rel=0&amp;mute=1", "https://www.youtube.com/embed/PlnX8RxF8Mc?rel=0&amp;mute=1", "https://www.youtube.com/embed/p_TyXS8Saj0?rel=0&amp;mute=1", "https://www.youtube.com/embed/a4nFbFZ0ghw?rel=0&amp;mute=1"],
            files: [LiftPlaque_Сatalogue_01, LiftPlaque_Сatalogue_02, LiftPlaque_Сatalogue_03, LiftPlaque_Сatalogue_04, LiftPlaque_Сatalogue_05, LiftPlaque_Сatalogue_06]
      },

]