export const LiftPlaque_01 = require('./img/companies/LiftPlaque_01.webp');
export const LiftPlaque_02 = require('./img/companies/LiftPlaque_02.webp');
export const LiftPlaque_03 = require('./img/companies/LiftPlaque_03.webp');
export const LiftPlaque_Logo = require('./img/companies/LiftPlaque_Logo.webp');
export const LiftPlaque_04 = require('./img/companies/LiftPlaque_04.webp');
export const LiftPlaque_Сatalogue_01 = require('./Catalogues/Fiche Technique Balai aimante 2021 LIFTPLAQUE.pdf');
export const LiftPlaque_Сatalogue_02 = require('./Catalogues/Fiche Technique Liftmagnet 2021 LIFTPLAQUE.pdf');
export const LiftPlaque_Сatalogue_03 = require('./Catalogues/Fiche Technique 2021 LIFTPLAQUE.pdf');
export const LiftPlaque_Сatalogue_04 = require('./Catalogues/Fiche Technique Mini Liftplaq PLUS.pdf');
export const LiftPlaque_Сatalogue_05 = require('./Catalogues/Fiche Technique Mini Liftplaq 2021.pdf');
export const LiftPlaque_Сatalogue_06 = require('./Catalogues/Fiche Technique Rainette LIFTPLAQUE.pdf');

export const WAConception_01 = require('./img/companies/WAConception_01.webp');
export const WAConception_02 = require('./img/companies/WAConception_02.webp');
export const WAConception_03 = require('./img/companies/WAConception_03.webp');
export const WAConception_04 = require('./img/companies/WAConception_04.webp');
export const WAConception_Logo = require('./img/companies/WAConception_Logo.webp');
export const WAConception_Сatalogue_01 = require('./Catalogues/Catalogue photovoltaique WA CONCEPTION.pdf');
export const WAConception_Сatalogue_02 = require('./Catalogues/Presentation WA CONCEPTION.pdf');

export const GCEI_01 = require('./img/companies/GCEI_01.webp');
export const GCEI_02 = require('./img/companies/GCEI_02.webp');
export const GCEI_03 = require('./img/companies/GCEI_03.webp');
export const GCEI_04 = require('./img/companies/GCEI_04.webp');
export const GCEI_Logo = require('./img/companies/GCEI_Logo.webp');
export const GCEI_Сatalogue_01 = require('./Catalogues/Présentation GCEI 01.pdf');
export const GCEI_Сatalogue_02 = require('./Catalogues/Présentation GCEI 02.pdf');

