import { NavLink } from 'react-router-dom';
import logo from '../../assets/img/logoWhite.webp'

function Footer(){
return(
      <footer>
            <div className="footer-content">
                  <div className="footer-content-block">
                        <a href="/">
                              <img className="logo" src={logo} alt="logo" tabIndex="0" aria-label="Revenir à la page principale"/>
                        </a>
                  </div>
                  <div className="footer-content-block">
                        <div className="footer-content-block-text">
                              <a className="sub" href="/conditions-generales" tabIndex="0" aria-label="Consulter les conditions générales"><span className="sub">Conditions générales</span></a>
                        </div>
                  </div>
            </div>
            <span id="copyrights">© Copyright 2024. Made by Maria Roudier</span>
      </footer>
)
}

export default Footer;