import React from 'react';

function Company({ openModal, mobile, title, cover, images, url, color, logo, files, videos }){
      return (
            <>
                  <div className='company' style={{ borderColor: color}}>
                        { title === "GCEI" ? ( 
                        <div className='company-top' style={{ backgroundColor: 'white', borderBottom: `2px solid ${color}`, borderTop: mobile ? `2px solid ${color}` : ''}}>
                              <a className='logo-box' tabIndex='0' aria-label='Visiter le site'>
                                    <img href={url} src={logo} alt={title}/>
                              </a>
                        </div> ) : (
                        <div className='company-top' style={{ backgroundColor: color}}>
                              <a className='logo-box' tabIndex='0' aria-label='Visiter le site'>
                                    <img href={url} src={logo} alt={title}/>
                              </a>
                        </div> )}
                        
                        <div className='company-content'>
                              <div className='company-info-block'>
                                    <div className='image-block'>
                                          <img className='img-in-block' src={cover} alt={title}/>
                                    </div>
                                    {mobile === false ? (
                                          <div className='company-text'>
                                          { title === "GCEI" ? (<>
                                                <span className='sub'>Nous proposons des solutions basées sur les technologies radio pour vous permettre, de collecter vos données de consommations, de les analyser et de mettre en place des actions<span className='sub-bold'> pour maitriser et réduire vos dépenses énergétiques</span>.</span>
                                                <br/>
                                                <span className='sub'>Nous intervenons dans tous les domaines d’activités : l’industrie et les bâtiments tertiaire, les mairies et les collectivités, les campings et hôtellerie de plein air, les golfs et les ports de plaisance.</span>
                                                <br/>
                                                <span className='sub'>Nos solutions de télégestion reposent sur des boîtiers connectés qui collectent<span className='sub-bold'> en temps réel </span>des données sur vos équipements et installations.</span>
                                                <span className='sub'>Ces informations sont ensuite transmises sur notre plateforme de supervision HYDRACOM, <span className='sub-bold'>accessible depuis votre ordinateur, tablette ou smartphone</span>.</span>
                                          </>) :
                                          title === "WA CONCEPTION" ? (<>
                                                <span className='sub-bold'>RECHERCHE & INNOVATION</span>
                                                <span className='sub'>Le groupe WA s’appuie sur 15 ans d’expérience dans le photovoltaïque au travers de ses deux entités spécialisées: WA Conception pour vos projets de 3 à 36 kW, de l’unité à la grande série.</span>
                                                <br/>
                                                <span className='sub-bold'>PROXIMITÉ & SERVICES</span>
                                                <span className='sub'>Un espace professionnel pour retrouver notices, visuels, conseils d’installation, pièces détachées.</span>
                                                <br/>
                                                <span className='sub-bold'>CONFORMITÉ</span>
                                                <span className='sub'>Nos produits sont conformes aux normes NF C15-100 / UTE C 15-712-1. Les parafoudres que nous utilisons sont conformes à la norme EN 61643-11 ou EN 61643-31.</span>
                                          </>) : 
                                          title === "LIFTPLAQUE" ? (<>
                                                <span className='sub'>Le LIFTPLAQ® et ses différents accessoires permettent de <span className='sub-bold'>manipuler tous les types de plaques et tampons</span> en toute sécurité, sans les toucher et sans effort.</span>
                                                <br/>
                                                <span className='sub-bold'>UN AIMANT PUISSANT</span>
                                                <span className='sub'>L’aimant du LIFTPLAQ® peut soulever des charges jusqu’à 110 kg en sécurité, tout en respectant les bons gestes et postures. La capacité de levage du lève plaque magnétique est de 450Kg en conditions optimales. Ce système magnétique permanent ne perd pas d’aimantation dans le temps et ne nécessite pas de contrôle périodique.</span>
                                                <br/>
                                                <span className='sub-bold'>UNE UTILISATION SIMPLE ET SÉCURISÉE</span>
                                                <span className='sub'>Lors de la manipulation, le LIFTPLAQ® permet à l’utilisateur de travailler seul et en toute sécurité : les mains ne sont plus en contact avec la plaque, et ses couleurs rouge et jaune lui assurent une grande visibilité sur le terrain.</span>
                                          </>) : ""
                                          }
                                          </div>)
                                    : (
                                          <div className='company-text'>
                                          { title === "GCEI" ? (<>
                                                <span className='sub'>Des solutions basées sur les technologies radio pour collecter vos données de consommations, de les analyser et de mettre en place des actions<span className='sub-bold'> pour maitriser et réduire vos dépenses énergétiques</span>.</span>
                                                <span className='sub'>Nos solutions de télégestion reposent sur des boîtiers connectés qui collectent<span className='sub-bold'> en temps réel </span>des données sur vos équipements et installations.</span>
                                                <span className='sub'>Ces informations sont ensuite transmises sur notre plateforme de supervision HYDRACOM, <span className='sub-bold'>accessible depuis votre ordinateur, tablette ou smartphone</span>.</span>
                                          </>) :
                                          title === "WA CONCEPTION" ? (<>
                                                <span className='sub-bold'>RECHERCHE & INNOVATION</span>
                                                <span className='sub'>Le groupe WA s’appuie sur 15 ans d’expérience dans le photovoltaïque au travers de ses deux entités spécialisées: WA Conception pour vos projets de 3 à 36 kW, de l’unité à la grande série.</span>
                                                <br/>
                                                <span className='sub-bold'>PROXIMITÉ & SERVICES</span>
                                                <span className='sub'>1 espace professionnel pour retrouver notices, visuels, conseils d’installation, pièces détachées.</span>
                                                <br/>
                                                <span className='sub-bold'>CONFORMITÉ</span>
                                                <span className='sub'>Nos produits sont conformes aux normes NF C15-100 / UTE C 15-712-1. Les parafoudres que nous utilisons sont conformes à la norme EN 61643-11 ou EN 61643-31.</span>
                                          </>) : 
                                          title === "LIFTPLAQUE" ? (<>
                                                <span className='sub'>Le LIFTPLAQ® et ses différents accessoires permettent de <span className='sub-bold'>manipuler tous les types de plaques et tampons</span> en toute sécurité, sans les toucher et sans effort.</span>
                                                <br/>
                                                <span className='sub-bold'>UN AIMANT PUISSANT</span>
                                                <span className='sub'>L’aimant du LIFTPLAQ® peut soulever des charges jusqu’à 110 kg en sécurité, tout en respectant les bons gestes et postures. La capacité de levage du lève plaque magnétique est de 450Kg en conditions optimales. Ce système magnétique permanent ne perd pas d’aimantation dans le temps et ne nécessite pas de contrôle périodique.</span>
                                                <br/>
                                                <span className='sub-bold'>UNE UTILISATION SIMPLE ET SÉCURISÉE</span>
                                                <span className='sub'>Lors de la manipulation, le LIFTPLAQ® permet à l’utilisateur de travailler seul et en toute sécurité : les mains ne sont plus en contact avec la plaque, et ses couleurs rouge et jaune lui assurent une grande visibilité sur le terrain.</span>
                                          </>) : ""
                                          }
                                          </div>
                                    )}
                              </div>
                              <div className='company-images'>
                                    <div className='images-row'>
                                    { 
                                          images.map((img, index) => { 
                                                return (
                                                      <div className='image-block' key={`${title}_${index}`}>
                                                            <img className='img-in-block' src={img} alt={title}/>
                                                      </div>
                                                )
                                          })
                                    }
                                    </div>
                              </div>
                        </div>
                        <div className='company-content-buttons'>
                              <div className='btn' onClick={ () => openModal(files)} tabIndex='0' aria-label='Consulter le catalogue'>
                                    <span className='link'>Catalogue</span>
                              </div>
                              { title === "LIFTPLAQUE" ? (
                                    <>
                                          <div className='btn' onClick={ () => openModal(videos)} tabIndex='0' aria-label='Consulter les vidéos'>
                                                <span className='link'>Vidéos</span>
                                          </div>
                                    </>
                              ) : "" }
                        </div>
                  </div>
            </>
      )
}

export default Company;