import React from 'react';

function Dialog({ closeModal, dataModal, mobile }) {

      const pushKey = () => {
            window.addEventListener("keyup", (e) => {
                  if (e.key === "Escape" || e.key === "Enter") {
                        closeModal()
                  }
            })
      };

      let typeData = ''
      if (dataModal[0].includes("pdf")) {
            typeData = 'pdf'
      } else if (dataModal[0].includes("youtube")) {
            typeData = 'youtube'
      }

      
      return (
            <>
                  <div id="modal-bg">
                  {typeData === 'youtube' ? (
                        <div id="modal-box" style={mobile === false ? {width: '80%', height: '80%'} : {}}>
                              <span className="cross" role="button" aria-label="Fermer le dialogue" tabIndex="0" onClick={() => closeModal()}>X</span>
                              <div id="modal-content">
                                    <div id="modal-title">
                                          <span className="heading-third">Vidéos disponibles</span>
                                          <hr/>
                                    </div>
                                    <div id="modal-files">
                                          <div id="modal-videos">
                                                { dataModal.map((el, index) => {
                                                      return ( 
                                                            <article key={index} tabIndex='0' aria-label="Consulter le vidéo">
                                                                  <iframe width={mobile ? "330" : "400"} height="300" src={el} title="YouTube video player" frameBorder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                            </article>
                                                      )
                                                })}
                                          </div>
                                    </div>
                              </div>
                         </div>
                  ) : (
                        <div id="modal-box">
                                    <span className="cross" role="button" aria-label="Fermer le dialogue" tabIndex="0" onClick={() => closeModal()} onFocus={() => pushKey()}>X</span>
                              <div id="modal-content">
                                    <div id="modal-title" style={mobile ? {marginBottom: '45px'} : {}}>
                                          <span className="heading-third">Catalogues disponibles</span>
                                          <hr/>
                                    </div>
                                    <div id="modal-files">
                                    { dataModal.map((el, index) => {
                                          let str = el.split('/');
                                          let newStr = str[3].split('.')
                                          let name = newStr[0]
                                          let format = el.split('.')
                                          return (
                                                <a href={el} key={index} tabIndex="0" aria-label="Consulter le document">{`${name}.${format[2]}`}</a>
                                          )
                                    })}
                                    </div>
                              </div>
                        </div>
                  )}
                  </div>
            </>
      );
};

export default Dialog; 