import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

import Header from '../../Components/Header/Header'
import Dialog from '../../Components/Dialog/Dialog'
import Footer from '../../Components/Footer/Footer'
import Company from '../../Components/Company/Company'

import GoogleMaps from '../../assets/img/GoogleCartes.webp'
import CatalogueGeneral from '../../assets/Catalogues/Catalogue ALLIANCE INDUSTRIE.pdf';

import { companies } from '../../data/data';

function Home(){

      const [stateModal, setState] = React.useState({
            isOpen: false,
            data: "test"
      })

      const [ mobile, setMobile] = React.useState('')

      useEffect(() => {
            if(window.screen.width <= 600) {
                  setMobile(true)
            } else{
                  setMobile(false)
            }
            window.addEventListener('resize', function(event) {
                  if(window.screen.width <= 600) {
                        setMobile(true)
                  } else{
                        setMobile(false)
                  }
            });
      },[])
      
      // function openLink(str) {
      //       let url = window.location.href
      //       let path = ''
      //       if(str === CatalogueGeneral) {
      //             path = str; 
      //       } else {
      //            path = str[0]; 
      //       }
      //       let fileUrl = url.slice(0, -1) + path
      //       window.open(fileUrl)
      // }
      function closeModal (){
            setState({isOpen: false, data: ""})
      }
      function openModal(data){
            if(data.length > 1) {
                  setState({isOpen: true, data: data})
            }
            // } else {
            //       openLink(data)
            // }
      }

      return (
            <>
            { stateModal.isOpen && <Dialog closeModal={closeModal} dataModal={stateModal.data} mobile={mobile} /> }
            <Header mobile={mobile} />
            <section id="home-hero">
                  <div id="home-hero-content">
                        <div id="home-hero-title">
                              <span className="heading-first">ALLIANCE INDUSTRIE</span>
                              {/* <h2 className='heading-second'>Distributeur et représentant</h2> */}
                        </div>
                        <div className="btn" tabIndex="0" aria-label="Consulter les produits">
                              <Link className="link" to="products"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={800}>
                                    VOIR NOS PRODUITS
                              </Link>
                        </div>
                  </div>
            </section>
            <section id="products">
                  <div style={{width: "100%"}}>
                        <h2 className="heading-second">NOS PRODUITS</h2>
                        <hr/>
                  </div>
                  <div id="companies-list">
                        { companies.map(company => {
                              return (
                                    <Company openModal={openModal} mobile={mobile} key={company.name} title={company.name} cover={company.cover} images={company.images} color={company.color} logo={company.logo} files={company.files} videos={company.videos}/>
                                         
                              )
                        })}
                  </div>
                  <div className="btn" tabIndex="0" aria-label="Consulter le catalogue général">
                        <a className="link" href={CatalogueGeneral}>Voir le catalogue général</a>
                  </div>
            </section>
            <section id="contact">
                  <div style={{width: "100%"}}>
                        <h2 className="heading-second">NOUS CONTACTER</h2>
                        <hr/>
                  </div>
                  <div className="contact-content">
                        <div id="contact-info">
                              <span className="text-lefted" tabIndex="0" aria-label="Envoyer email"><a href="mailto:m.lenglet@alliance-industrie.net"><span className="sub-bold">Email: </span>m.lenglet@alliance-industrie.net</a></span>
                              <span className="text-lefted"><span className="sub-bold">Téléphone: </span>04 68 22 89 34</span>
                              <span className="text-lefted"><span className="sub-bold">Mobile: </span>07 60 95 68 01</span>
                              <span className="text-lefted"><span className="sub-bold">Adresse: </span>231 rue James Watt, zone tecnosud, 66100 Perpignan</span>
                        </div>
                        {mobile === false ? (
                        <a className="image-block" href="https://maps.app.goo.gl/WzHmXnoLjNsNLAHf7">
                              <img className="img-in-block" src={GoogleMaps} alt="Location"/>
                        </a>) : '' }

                  </div>
            </section>
            <Footer />
            </>
      )
}

export default Home;