import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './style.css';
import Home from './Pages/Home/Home';
import reportWebVitals from './reportWebVitals';
import LegalNotices from './Pages/LegalNotices/LegalNotices';
import NotFound from './Pages/NotFound/NotFound';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter> 
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/alliance-industrie" element={<Navigate to="/" replace />} />
      <Route path="/conditions-generales" element={<LegalNotices />} />
      <Route path='/notfound' element={<NotFound />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  </ BrowserRouter>
);

reportWebVitals();
