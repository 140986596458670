import React, { useState } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import logo from '../../assets/img/LogoWhiteHeader.png'
import hamburger from '../../assets/icons/hamburger.png'
import cross from '../../assets/icons/cross.png'

function Header({mobile}){

      const [mobNav, setMobNav] = React.useState('')
      const openNav = () => {
            setMobNav(true)
            return mobNav
      }

      const closeNav = () => {
            setMobNav(false)
            return mobNav
      }

      let className = "inverted";
      let scrollTrigger = 200;

      window.onscroll = function() {
            if(document.getElementsByTagName("header").length) {
                  if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
                        document.getElementsByTagName("header")[0].classList.add(className);
                        document.querySelector(".logo").style.display = 'none'
                  } else {
                        document.getElementsByTagName("header")[0].classList.remove(className);
                        document.querySelector(".logo").style.display = 'block'
                  }
            }
      };

      return (
            <header className={mobNav ? "nav-opened" : ""}>
                  <a className="logo-box" href="/">
                        <img className="logo" src={logo} alt="logo" tabIndex="0" aria-label="Revenir à la page principale"/>
                  </a>
                  { mobile === false ? (
                        <nav className="header-links">
                              <Link className="header-link" to="products" tabIndex="0" aria-label="Consulter les produits"
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={800}>
                                    NOS PRODUITS
                              </Link>
                              <Link className="header-link" to="contact" tabIndex="0" aria-label="Nous contacter"
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={800}>
                                    CONTACT
                              </Link>
                        </nav>
                        ) : (
                        <div className="header-mob">
                              <img src={hamburger} className={mobNav == true ? "icon-hidden" : "header-mob-icon"} onClick={() => openNav()} alt="menu" tabIndex="0" aria-label="Consulter le menu"/>
                              <img src={cross} className={mobNav == false ? "icon-hidden" : "header-mob-icon"} onClick={() => closeNav()} alt="close" tabIndex="0" aria-label="Fermer le menu"/>
                        </div>
                        )
                  }
                  { mobNav && (
                        <div className="header-mob-nav">
                              <nav className="header-mob-nav-box">
                                    <li className="header-mob-nav-list"><Link className="header-link" to="products" onClick={() => closeNav()} tabIndex="0" aria-label="Consulter les produits"
                                          activeClass="active"
                                          spy={true}
                                          smooth={true}
                                          offset={-70}
                                          duration={800}>
                                          NOS PRODUITS
                                    </Link></li>
                                    <li className="header-mob-nav-list"><Link className="header-link" to="contact" onClick={() => closeNav()} tabIndex="0" aria-label="Nous contacter"
                                          activeClass="active"
                                          spy={true}
                                          smooth={true}
                                          offset={-70}
                                          duration={800}>
                                          CONTACT
                                    </Link></li>
                              </nav>
                        </div>
                  )}
            </header>
      )
}

export default Header;