import React, { useState, useEffect } from 'react';

import Footer from '../../Components/Footer/Footer'

function LegalNotices(){

      const [stateModal, setState] = React.useState({
            isOpen: false,
            data: "test"
      })

      const [ mobile, setMobile] = React.useState('')

      useEffect(() => {
            if(window.screen.width <= 600) {
                  setMobile(true)
            } else{
                  setMobile(false)
            }
            window.addEventListener('resize', function(event) {
                  if(window.screen.width <= 600) {
                        setMobile(true)
                  } else{
                        setMobile(false)
                  }
            });
      },[])

      return(
            <>
            <section className="conditions-hero">
                  <span className="heading-second">Conditions générales</span>
            </section>
            <section className="conditions-text">
                  <span className="heading-third">Article 1 – Mentions légales</span>
                  <span className="sub-bold">1.1 Site (ci-après « le site »):</span><span> www.alliance-industrie.net</span>
                  <span className="sub-bold">1.2 Éditeur (ci-après « l'éditeur »):</span>
                  <span>SAS ALLIANCE INDUSTRIE</span>
                  <span>SAS au capital de 100€ - 231 rue James Watt, zone tecnosud, 66100 Perpignan, France</span>
                  <span>Tél : 04 68 22 89 34</span>
                  <span>E-mail :<a href="mailto:m.lenglet@alliance-industrie.net">m.lenglet@alliance-industrie.net</a></span>
                  <span>Dirigeant : Michael Lenglet</span>
                  <span className="sub-bold">1.3 Créateur (ci-après « le créateur »)</span>
                  <span>www.alliance-industrie.net a été créé par :</span>
                  <span>Maria Roudier</span>
                  <span>Email : <a href="mailto:mariaroudier@gmail.com">mariaroudier@gmail.com</a></span>
                  <span className="sub-bold">1.4 Hébergeur (ci-après « l'hébergeur »):</span>
                  <p>www.alliance-industrie.net est hébergé par OVHcloud - 2 rue Kellermann - 59100 Roubaix - France</p>
                  <span className="sub-bold">1.5 Délégué à la protection des données (DPO):</span>
                  <p>Délégué à la protection des données : Michael Lenglet. Le DPO est à votre disposition pour toute question relative à la protection de vos données personnelles.</p>
                  <span className="heading-third">Article 2 – Accès au site</span>
                  <p>L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l'envoi de courriers électroniques non sollicités.</p>
                  <span className="heading-third">Article 3 - Contenu du site</span>
                  <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété intellectuelle. Ils sont la propriété pleine et entière de l'éditeur ou de ses partenaires. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'éditeur, sont strictement interdites. Le fait pour l'éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>
                  <span className="heading-third">Article 4 – Gestion du site</span>
                  <p>Pour la bonne gestion du site, l'éditeur pourra à tout moment :</p>
                  <br/>
                  <ul>
                  <li>- suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internautes ;</li>
                  <li>- supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la Nétiquette</li>
                  <li>- suspendre le site afin de procéder à des mises à jour.</li>
                  </ul>
                  <br/>
                  <span className="heading-third">Article 5 – Responsabilités</span>
                  <p>La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités. Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d'attaques virales par Internet. Vous êtes par ailleurs seul responsable des sites et données que vous consultez. L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :</p>
                  <br/>
                  <ul>
                  <li>- du fait de l'usage du site ou de tout service accessible via Internet;</li>
                  <li>- du fait du non-respect par vous des présentes conditions générales.</li>
                  </ul>
                  <br/>
                  <p>L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait. Si l'éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de votre utilisation du site, il pourrait se retourner contre vous pour obtenir l'indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>
                  <span className="heading-third">Article 6 - Liens hypertextes</span>
                  <p>La mise en place par les utilisateurs de tous liens hypertextes vers tout ou partie du site est autorisée par l'éditeur. Tout lien devra être retiré sur simple demande de l'éditeur. Toute information accessible via un lien vers d'autres sites n'est pas publiée par l'éditeur. L'éditeur ne dispose d'aucun droit sur le contenu présent dans ledit lien.</p>
                  <span className="heading-third">Article 7 - Photographies et représentation des produits</span>
                  <p>Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n'engagent pas l'éditeur.</p>
                  <span className="heading-third">Article 8 - Loi applicable</span>
                  <p>Les présentes conditions d'utilisation du site sont régies par la loi française et soumises à la compétence des tribunaux du siège social de l'éditeur, sous réserve d'une attribution de compétence spécifique découlant d'un texte de loi ou réglementaire particulier.</p>
                  <span className="heading-third">Article 9 – Nous contacter</span>
                  <p>Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous pouvez envoyer un message par email en cliquant <a href="mailto:m.lenglet@alliance-industrie.ne">ici</a></p>
            </section>
            < Footer />
            </>
      )
}

export default LegalNotices;